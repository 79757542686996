'use client';

import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAddFavoriteMutation,
  useGetFavoritesQuery,
  useRemoveFavoriteMutation
} from '@akinon/next/data/client/wishlist';
import { Icon, Modal } from 'components';
import { useRouter } from '@akinon/next/hooks';
import { ROUTES } from 'routes';
import { usePathname } from 'next/navigation';
import { pushAddWishlist } from 'utils/gtm';
import { Product } from '@akinon/next/types';
import { useLocalization } from '@akinon/next/hooks';

interface FavButtonProps {
  label?: string;
  size?: number;
  className?: string;
}

const useFavButton = (productPk: number, product?: Product) => {
  const { status } = useSession();
  const { data: favorites } = useGetFavoritesQuery(
    {
      limit: 1000
    },
    { skip: status !== 'authenticated' }
  );
  const [isActive, setIsActive] = useState(false);
  const [addFavorite, { isSuccess: isAddSuccess }] = useAddFavoriteMutation();
  const [removeFavorite] = useRemoveFavoriteMutation();
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const { t } = useLocalization();

  const handleClick = useCallback(() => {
    if (isActive) {
      const favPk = favorites?.results?.find(
        (item) => Number(item.product.pk) === Number(productPk)
      )?.pk;

      removeFavorite(favPk);
    } else {
      if (status !== 'authenticated') {
        router.push(ROUTES.LOGIN + `?callbackUrl=${pathname}`);
      } else {
        addFavorite(productPk).unwrap();
        pushAddWishlist(product);
      }
    }
  }, [
    isActive,
    favorites?.results,
    removeFavorite,
    productPk,
    router,
    pathname,
    status,
    addFavorite
  ]);

  useEffect(() => {
    if (isAddSuccess) {
      setShowFavoriteModal(true);
    }
  }, [isAddSuccess]);

  useEffect(() => {
    setIsActive(
      !!favorites?.results?.find(
        (item) => Number(item.product.pk) === Number(productPk)
      )
    );
  }, [favorites?.results, productPk]);

  const FavButton = useMemo(() => {
    const View = (props: FavButtonProps) => (
      <>
        {showFavoriteModal && (
          <Modal
            setOpen={setShowFavoriteModal}
            open={showFavoriteModal}
            portalId="add-favorite-product"
          >
            <div className="p-4 text-md">{t('product.added_to_wishlist')}</div>
          </Modal>
        )}
        <button
          className={clsx(
            'flex items-center hover:text-secondary-hover hover:cursor-pointer',
            props.className
          )}
          onClick={handleClick}
          data-testid="favourites-icon"
        >
          <Icon
            name={isActive ? 'heart-full' : 'heart-stroke'}
            size={props.size ?? 24}
            className={clsx('hover:fill-secondary-hover')}
          />
          {props.label && <span className="ml-2">{props.label}</span>}
        </button>
      </>
    );

    return View;
  }, [isActive, handleClick]);

  return {
    FavButton
  };
};

export default useFavButton;
