'use client';

import { useAppDispatch } from '@akinon/next/redux/hooks';
import {
  useAddProductMutation,
  AddProductRequest
} from '@akinon/next/data/client/product';
import { basketApi } from '@akinon/next/data/client/basket';
import {
  openMiniBasket,
  setHighlightedItem
} from '@akinon/next/redux/reducers/root';
import { useToast } from './use-toast';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const useAddProductToBasket = () => {
  const dispatch = useAppDispatch();
  const [addProduct, options] = useAddProductMutation();
  const toast = useToast();

  const addProductToBasket = async ({
    product,
    quantity,
    attributes
  }: Optional<AddProductRequest, 'attributes'>) => {
    await addProduct({
      product,
      quantity,
      attributes
    })
      .unwrap()
      .then((data) =>
        dispatch(
          basketApi.util.updateQueryData(
            'getBasket',
            undefined,
            (draftBasket) => {
              Object.assign(draftBasket, data.basket);
            }
          )
        )
      )
      .then(() => {
        dispatch(openMiniBasket());
        dispatch(setHighlightedItem(product));
        setTimeout(() => {
          dispatch(setHighlightedItem(null));
        }, 3000);
      })
      .catch((err) => {
        if (err && err.status === 406) {
          const message = err.data?.non_field_errors ?? '';
          message && toast.open(
            message
          );
        }
      })
      ;
  };

  return [addProductToBasket, options] as const;
};
