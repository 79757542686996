'use client';

import { useEffect, useState } from 'react';
import {
  pushAddToCart,
  pushProductClicked,
} from 'utils/gtm';
import { useInView } from 'react-intersection-observer';
import useFavButton from 'hooks/use-fav-button';
import { IMAGE_OPTIONS } from 'utils';
import { Product } from '@akinon/next/types';
import { Price, Link, Button, Image } from 'components';
import { useLocalization } from '@akinon/next/hooks';
import { useAddProductToBasket } from 'hooks';
import { twMerge } from 'tailwind-merge';

interface Props {
  product: Product;
  imageKey?: keyof typeof IMAGE_OPTIONS;
  width?: number;
  height?: number;
  index: number;
}

export const ProductItem = (props: Props) => {
  // TODO: Static image will change (TR)
  const { product, imageKey, width, height, index } = props;
  const [viewed, setViewed] = useState(false);
  const { FavButton } = useFavButton(product.pk, product);
  const { ref, inView } = useInView();
  const [addProduct, { isLoading }] = useAddProductToBasket();
  const { t } = useLocalization();

  const image_url = product.productimage_set[0]?.image;
  const absolute_url = product.absolute_url;
  const product_name = product.name;
  const retail_price = product.retail_price;
  const price = product.price;
  const productQuantityAndUnit = product.attributes?.ml;

  const addProductToCart = () => {
    addProduct({ product: product.pk, quantity: 1, attributes: {} });
    pushAddToCart(product);
  };

  useEffect(() => {
    if (!viewed && inView) {
      setViewed(true);
    }
  }, [inView]);

  return (
    <div
      className="text-sm text-left flex flex-col justify-between bg-gray-20"
      ref={ref}
    >
      <div className="relative">
        <Link href={absolute_url} onClick={() => pushProductClicked(product)}>
          {image_url ? (
            <Image
              fill
              loading="lazy"
              src={image_url}
              alt={product_name}
              className="aspect-square"
            />
          ) : (
            <Image
              className="h-full"
              src="/noimage.jpg"
              fill
              alt={product_name}
            />
          )}
        </Link>
        <div className="absolute top-1 right-1 bg-white text-primary-400 px-4 py-1 font-sans text-[9px] leading-[11px] shadow-new-arrival">
          {t('product.new_arrival')}
        </div>
      </div>
      <div className="relative p-3 flex flex-col h-full">
        <div className="flex-1">
          <Link
            href={absolute_url}
            className="text-sm text-primary leading-5 min-h-[40px] font-serif w-8/10 text-ellipsis overflow-hidden line-clamp-2 whitespace-normal rtl:text-right"
            data-testid={`${product_name}-${index}`}
            onClick={() => pushProductClicked(product)}
          >
            {product_name}
          </Link>
          <div className="text-xs text-gray-750 leading-5 font-sans rtl:text-right">
            {product.attributes?.USP}
          </div>
          <div className="text-primary-400 text-xs leading-4 font-sans my-1 rtl:text-right">
            {productQuantityAndUnit}
          </div>
          <div className="md:mt-5 flex flex-col md:flex-row rtl:items-start">
            <Price
              className={twMerge(
                "text-primary-400 text-sm font-sans uppercase",
                Number(retail_price) > Number(price) && "text-secondary"
              )}
              value={price}
              useCurrencyAfterPrice={false}
              data-testid="product-price"
            />
            {Number(retail_price) > Number(price) && (
              <Price
                value={retail_price}
                useCurrencyAfterPrice={false}
                className="text-gray-650 font-normal line-through ml-0 md:ml-2 md:rtl:mr-2 text-sm font-sans uppercase"
              />
            )}
          </div>
          <FavButton
            size={20}
            className="absolute top-3 right-3 rtl:left-3 rtl:right-[unset]"
          />
        </div>
        <Button
          disabled={!product.in_stock || isLoading}
          className="w-full h-12 mt-2 disabled:bg-opacity-50 disabled:cursor-not-allowed font-sans"
          onClick={addProductToCart}
        >
          {t('product.add_to_cart')}
        </Button>
      </div>
    </div>
  );
};
