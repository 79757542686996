'use client';

import { GTMEvent, Product, GTMUserType } from '@akinon/next/types';
import { GTMGA4EventData } from 'types';
import dayjs from 'dayjs';
import { createEcommerceItem } from '.';

export const pushPageView = (url: string) => {
  window.dataLayer.push({
    event: 'pageView',
    page: url
  });
};

export const pushAddToCart = (product: Product) => {
  pushEventGA4({
    event: 'add_to_cart',
    ecommerce: {
      value: Number(product?.price),
      currency: product?.currency_type?.toUpperCase() ?? 'AED',
      items: [
        createEcommerceItem({
          product
        })
      ]
    }
  });
};

export const pushRemoveFromCart = (basketItem) => {
  pushEventGA4({
    event: 'remove_from_cart',
    ecommerce: {
      currency: basketItem?.product?.currency_type?.toUpperCase() ?? 'AED',
      value: Number(basketItem?.product?.price),
      items: [
        createEcommerceItem({
          product: basketItem?.product,
          quantity: basketItem?.quantity
        })
      ]
    }
  });
};

export const pushCartView = (basket) => {
  pushEventGA4({
    event: 'view_cart',
    ecommerce: {
      currency:
        basket?.basketitem_set[0]?.currency_type?.toUpperCase() ?? 'AED',
      value: Number(basket?.total_amount),
      items: basket?.basketitem_set?.map((item) => createEcommerceItem(item))
    }
  });
};

export const pushCheckoutStep = (basket) => {
  pushEventGA4({
    event: 'begin_checkout',
    ecommerce: {
      currency:
        basket?.basketitem_set[0]?.currency_type?.toUpperCase() ?? 'AED',
      coupon:
        basket?.discounts.length > 0 ? basket?.discounts[0]?.description : '',
      value: Number(basket?.total_amount),
      items: basket?.basketitem_set?.map((item) => createEcommerceItem(item))
    }
  });
};

export const pushCheckoutStepShipping = (checkoutData) => {
  pushEventGA4({
    event: 'add_shipping_info',
    ecommerce: {
      currency:
        checkoutData.basket?.basketitem_set[0]?.currency_type?.toUpperCase() ??
        'AED',
      value: Number(checkoutData.basket?.total_amount),
      coupon:
        checkoutData.basket?.discounts?.length > 0
          ? checkoutData.basket?.discounts[0]?.description
          : '',
      shipping_tier: checkoutData.delivery_option?.name ?? '',
      items: checkoutData.basket?.basketitem_set?.map((item) =>
        createEcommerceItem(item)
      )
    }
  });
};

export const pushCheckoutStepPayment = (checkoutData, payment) => {
  pushEventGA4({
    event: 'add_payment_info',
    ecommerce: {
      currency:
        checkoutData.basket?.basketitem_set[0]?.currency_type?.toUpperCase() ??
        'AED',
      value: Number(checkoutData.basket?.total_amount),
      coupon:
        checkoutData.basket?.discounts.length > 0
          ? checkoutData.basket?.discounts[0]?.description
          : '',
      payment_method: payment ?? '',
      items: checkoutData.basket?.basketitem_set?.map((item) =>
        createEcommerceItem(item)
      )
    }
  });
};

export const pushProductClicked = (product: Product) => {
  const params = new URL(window.location.toString()).searchParams;
  const hasSearchResultPage = params.has('search_text');

  pushEventGA4({
    event: 'select_item',
    ecommerce: {
      item_list_id: 'product_list',
      item_list_name: `${
        hasSearchResultPage ? 'Search Result' : 'Product List'
      }`,
      items: [
        createEcommerceItem({
          product,
          pageType: hasSearchResultPage ? 'Search Result' : 'Product List'
        })
      ]
    }
  });
};

export const pushProductViewed = (product: Product) => {
  const params = new URL(window.location.toString()).searchParams;
  const hasSearchResultPage = params.has('search_text');

  pushEventGA4({
    event: 'view_item',
    ecommerce: {
      item_list_id: 'product_detail',
      item_list_name: `${
        hasSearchResultPage ? 'Search Result' : 'Product Detail'
      }`,
      currency: product?.currency_type?.toUpperCase() ?? 'AED',
      value: Number(product?.price),
      items: [createEcommerceItem({ product })]
    }
  });
};

export const pushAddWishlist = (product: Product) => {
  pushEventGA4({
    event: 'add_to_wishlist',
    ecommerce: {
      value: Number(product?.price),
      currency: product?.currency_type?.toUpperCase() ?? 'AED',
      items: [createEcommerceItem({ product })]
    }
  });
};

export const pushUserData = (
  value: Pick<
    GTMUserType,
    'pk' | 'hashedEmail' | 'dateJoined' | 'gender' | 'emailAllowed'
  >
) => {
  window.dataLayer.push({
    id: value.pk,
    user: {
      email: value.hashedEmail || '',
      signupDate:
        value.dateJoined + ' | ' + dayjs(value.dateJoined).format('DD-MM-YY'),
      gender: value.gender || '',
      permission: value.emailAllowed ? 'yes' : 'no'
    }
  });
};

export const pushEvent = (event: GTMEvent) => {
  const { Category = 'Enhanced Ecommerce', Value = 0, ...rest } = event;

  window.dataLayer.push({ Category, Value, ...rest });
};

export const pushEventGA4 = (event: GTMGA4EventData) => {
  window.dataLayer.push(event);
};
