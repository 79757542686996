'use client';

import { Fragment } from 'react';
import clsx from 'clsx';

import { BreadcrumbResultType } from '@akinon/next/types';
import { useLocalization } from '@akinon/next/hooks';

import { Icon, Link } from 'components';
import { ROUTES } from 'routes';

export interface BreadcrumbProps {
  breadcrumbList?: BreadcrumbResultType[];
  useTranslation?: boolean;
  className?: string;
  iconSize?: number;
  linkClassName?: string;
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { t } = useLocalization();
  const { breadcrumbList = [] } = props;

  const list = [
    { url: ROUTES.HOME, text: t('common.breadcrumb.homepage') },
    ...breadcrumbList.map((breadcrumb) => {
      let textValue = breadcrumb.label;
      if (props.useTranslation === true) {
        textValue = t(breadcrumb.label);
      }
      return {
        url: breadcrumb.url,
        text: textValue
      };
    })
  ];

  return (
    <div
      className={clsx(
        'flex items-center gap-[8px] text-black flex-wrap sm:flex-nowrap',
        props?.className
          ? props?.className
          : 'text-[13px] font-sans leading-[20px] uppercase'
      )}
    >
      {list.map((item, index) => (
        <Fragment key={index}>
          <Link href={item.url} className={props.linkClassName}>
            {item.text}
          </Link>
          {index !== list.length - 1 && (
            <Icon
              name="chevron-end"
              size={props?.iconSize ? props?.iconSize : 8}
              className="leading-[12px] w-[12px] mt-[-4px] rtl:rotate-180 rtl:mr-2"
            />
          )}
        </Fragment>
      ))}
    </div>
  );
}
